/**
 * hide the app block based on oneLinkTemplateIdProd exists
 */
const hideAppBlock = () => {
  if (
    !window.pageData.dataCountryThemeSetting?.nodes[0].countryThemeSettings.oneLinkTemplateIdProd
  ) {
    const app = document.querySelector(".app-dynamic")
    if(!app) return
    app.classList.toggle("d-none")
    app.classList.toggle("d-flex")
  }
}

export default hideAppBlock